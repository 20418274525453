import React from "react"
import { Modal, ModalDialog, ModalContent, ModalBody, ModalBackdrop, CloseButton } from "../Modal"
import {CloseIcon} from "../Icons"
import ProductCard from "../ProductCard"

const QuotePopup = ({ isVisible, onClose, product }) =>  {
  return (
    <>
      <Modal
        className={"modal fade " + (isVisible ? "show" : "")}
        style={{ display: isVisible ? "block" : "none" }}
      >
        <ModalDialog className="modal-dialog modal-dialog-centered modal-lg modal-photo">
          <ModalContent className="modal-content">
            <CloseButton className="close" onClick={onClose}><CloseIcon /></CloseButton>
            <ModalBody className="modal-body p-0">
              <ProductCard sku={product.node.skuNumber} data={product.node} />
            </ModalBody>
          </ModalContent>
        </ModalDialog>
      </Modal>
      {isVisible && (
        <ModalBackdrop className={"fade " + (isVisible ? "show" : "")} />
      )}
    </>
  )
}

export default QuotePopup
